// App.js
import React, {useEffect} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/HomePage";
import RegistrationForm from "./components/OnlineRegistration";
import AboutUs from "./components/About";
import OurTeam from "./components/OurTeam";
import JavaCoursePage from "./components/JavaStack";
import PythonCoursePage from "./components/PythonStack";
import MernStackCoursePage from "./components/Mern";
import MeanStackCoursePage from "./components/Mean";
import DataAnalyticsCoursePage from "./components/DataAnalytics";
import DataScienceCoursePage from "./components/DataScience";
import ContactForm from "./components/Contact";
import CorporateTraining from "./components/CorporateTraining";
import ScrollToTop from "./components/ScrollToTop";
import Jobs from "./components/Jobs";


const App = ({title}) => {

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/registration" element={<RegistrationForm />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/course/java-full-stack" element={<JavaCoursePage />} />
        <Route path="/course/python-full-stack" element={<PythonCoursePage />} />
        <Route path="/course/mern-stack" element={<MernStackCoursePage />} />
        <Route path="/course/mean-stack" element={<MeanStackCoursePage />} />
        <Route path="/course/data-analytics" element={<DataAnalyticsCoursePage />} />
        <Route path="/course/data-science" element={<DataScienceCoursePage />} />
        <Route path="/contact-us" element={<ContactForm />} />
        <Route path="/corporate-training" element={<CorporateTraining  />} />
        <Route path="/jobs" element={<Jobs  />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
