import React from "react";
import Footer from "../components/Footer";
import emailjs from "emailjs-com";

function DataAnalyticsCoursePage() {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/brochure/analyticsBrochure.pdf";
    link.download = "analyticsBrochure.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Get the form element
    const form = event.target;

    // Send form data using EmailJS
    emailjs
      .sendForm(
        "service_ihwwo4e",
        "template_y4ak34k",
        form,
        "NJirMFVuxz3verfi0"
      )
      .then(
        (result) => {
          alert("Message sent successfully!");
          form.reset();
        },
        (error) => {
          alert("An error occurred. Please try again.");
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="java-course-page">
        {/* Banner Section */}
        <div className="banner" style={{ backgroundColor: "#003366" }}>
          <div className="banner-left">
            <h1>Data Analytics Training Program</h1>
            <div className="rating">
              {[...Array(5)].map((_, index) => (
                <span key={index} className="star">
                  &#9733;
                </span>
              ))}
            </div>
            <button className="brochure-button" onClick={handleDownload}>
              Download Brochure
            </button>
          </div>
          <div className="banner-right">
            <div className="form-container">
              <h2>Get More Information</h2>
              <form
                className="info-form"
                id="info-form"
                onSubmit={handleSubmit}
              >
                <input
                  type="text"
                  name="full_name"
                  placeholder="Full Name"
                  required
                  autoComplete="off"
                />
                <input type="email" name="email" placeholder="Email" required />
                <input
                  type="tel"
                  name="phone_number"
                  placeholder="Phone Number"
                  required
                  autoComplete="off"
                />
                <input
                  type="text"
                  name="course_name"
                  value="Data Analytics"
                  readOnly
                  className="readonly-field"
                  autoComplete="off"
                />
                <button type="submit" className="submit-button">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Course Overview Section */}
        <section className="course-overview card">
          <h2 className="card-header">
            What is Data Analytics Training Course?
          </h2>
          <p className="card-body">
            The Data Analytics Training Course is designed to provide in-depth
            knowledge and practical experience in analyzing and interpreting
            complex data. Students will learn to use various tools and
            techniques to extract insights from data, make data-driven
            decisions, and understand key trends and patterns.
          </p>
        </section>

        {/* Why Choose CodeWave Solutions Section */}
        <section className="why-choose card">
          <h2 className="card-header">
            Why Choose CodeWave Solutions for Data Analytics Training?
          </h2>
          <ul className="card-body">
            <li>
              Experienced Trainers: Learn from industry experts with years of
              experience.
            </li>
            <li>
              Job Assistance: Get help with job placement and career guidance.
            </li>
            <li>
              Flexible Learning: Choose from various learning modes to suit your
              schedule.
            </li>
            <li>
              Supportive Community: Join a network of learners and professionals
              for ongoing support.
            </li>
          </ul>
        </section>

        {/* Importance of Certification Section */}
        <section className="certification card">
          <h2 className="card-header">
            Why Data Analytics Certification is Essential
          </h2>
          <p className="card-body">
            Certification in Data Analytics demonstrates your proficiency and
            commitment to the field. It enhances your credibility, increases job
            prospects, and validates your skills to potential employers.
          </p>
        </section>

        {/* How to Become a Data Analyst Section */}
        <section className="how-to-become card">
          <h2 className="card-header">How to Become a Data Analyst?</h2>
          <p className="card-body">
            To become a Data Analyst, you need to master data manipulation,
            statistical analysis, and data visualization techniques. Start by
            learning tools such as Excel, SQL, and Python, and gain hands-on
            experience through projects and internships to build a strong
            portfolio.
          </p>
        </section>

        {/* Skills Required Section */}
        <section className="skills-required card">
          <h2 className="card-header">
            Skills Required to Become a Data Analyst
          </h2>
          <ul className="card-body">
            <li>Proficiency in data analysis tools and techniques.</li>
            <li>
              Understanding of statistical methods and data visualization.
            </li>
            <li>Experience with SQL and database management.</li>
            <li>Knowledge of programming languages such as Python or R.</li>
            <li>Strong problem-solving and analytical skills.</li>
          </ul>
        </section>

        {/* What Students Will Get Section */}
        <section className="student-benefits card">
          <h2 className="card-header">
            What Our Students Will Get During This Training Program?
          </h2>
          <ul className="card-body">
            <li>Comprehensive course materials and resources.</li>
            <li>Hands-on projects and real-world scenarios.</li>
            <li>Access to industry-leading tools and technologies.</li>
            <li>Personalized feedback and mentorship from instructors.</li>
            <li>Certificate of completion upon successful graduation.</li>
          </ul>
        </section>

        {/* Career Opportunities Section */}
        <section className="career-opportunities card">
          <h2 className="card-header">
            Career Opportunities After Learning Data Analytics
          </h2>
          <p className="card-body">
            Graduates of the Data Analytics course can pursue various career
            paths, including:
          </p>
          <ul className="card-body">
            <li>Data Analyst</li>
            <li>Business Intelligence Analyst</li>
            <li>Data Scientist</li>
            <li>Market Research Analyst</li>
            <li>Data Consultant</li>
            <li>Data Engineer</li>
          </ul>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default DataAnalyticsCoursePage;
