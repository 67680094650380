import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/Header.css";
import CWSLogo from '../images/logo.jpeg';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false); // Close the menu when a link is clicked
    setDropdownOpen(false); // Close the dropdown when a link is clicked
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="header">
      <div className="logo">
        {/* <img src={CWSLogo} alt="Logo"/> */}
        {/* Write text for logo 'CWS' in stylish manner */}
        <span className="logo-text">CWS</span>
       <div className="logoLine"></div>
      </div>
      <div className={`headerLinksDiv ${menuOpen ? "active" : ""}`}>
        <Link to="/home" onClick={handleLinkClick}>Home</Link>
        <div className="dropdown">
          <button onClick={toggleDropdown} style={{fontWeight: 'bold'}}>Courses</button>
          <div className={`dropdown-content ${dropdownOpen ? "active" : ""}`}>
            <Link to="/course/java-full-stack" onClick={handleLinkClick}>Java Full Stack</Link>
            <Link to="/course/data-science" onClick={handleLinkClick}>Data Science</Link>
            <Link to="/course/data-analytics" onClick={handleLinkClick}>Data Analytics</Link>
            <Link to="/course/python-full-stack" onClick={handleLinkClick}>Python Full Stack</Link>
            <Link to="/course/mean-stack" onClick={handleLinkClick}>Mean Stack</Link>
            <Link to="/course/mern-stack" onClick={handleLinkClick}>Mern Stack</Link>
          </div>
        </div>
        <Link to="/corporate-training" onClick={handleLinkClick}>Corporate Training</Link>
        <Link to="/registration" onClick={handleLinkClick}>Eligibility Criteria</Link>
        <Link to="/contact-us" onClick={handleLinkClick}>Contact Us</Link>
        <Link to="/our-team" onClick={handleLinkClick}>Our Team</Link>
        <Link to="/about-us" onClick={handleLinkClick}>About Us</Link>
        <Link to="/jobs" onClick={handleLinkClick}>Jobs</Link>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
};

export default Header;
